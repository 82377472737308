import React, { useState, useEffect } from 'react';
import useAptitudeTestResponsesContext from 'hooks/useAptitudeTestResponseContext';
import {
  useFinishAptitudeTestMutation,
  useUpdateResponsesAptitudeMutation,
} from '../AptitudeQuizSlice';
import Questions from './questions';

import AptitudeTestCardBar from '../layout/AptitudeTestCardBar';
import { useInterval } from 'hooks/useInterval';
import { useTimeout } from 'hooks/useTimeout';
import { useNavigate } from 'react-router-dom';

const Index: React.FC<{ data: any }> = ({ data }) => {
  const [updateResponsesAptitude] = useUpdateResponsesAptitudeMutation();
  const { state, dispatch } = useAptitudeTestResponsesContext();
  const [timeLeft, setTimeLeft] = useState(data.overallTimeLeft);
  const [finishAptitudeTest, { isLoading }] = useFinishAptitudeTestMutation();

  const navigate = useNavigate();

  function finishTest() {
    finishAptitudeTest({})
      .unwrap()
      .then((res: any) => {
        navigate('/quiz/aptitude/scorecard');
      });
  }
  //Main timeout
  useTimeout(() => {
    if (data && data.overallTimeLeft && timeLeft < 0) {
      finishTest();
    }
  }, timeLeft * 1000);

  useEffect(() => {
    updateResponsesAptitude({ responses: state.responses });
  }, [state.responses, updateResponsesAptitude]);

  //Main Timer
  useInterval(() => {
    const minutesLeft = Math.floor(timeLeft / 60);
    const secondsLeft = timeLeft % 60;
    dispatch({
      type: 'UPDATE_REMAINING_TIME',
      payload: { minutesLeft, secondsLeft },
    });
    setTimeLeft((timeLeft: number) => timeLeft - 1);
  }, 1000);

  return isLoading ? (
    <p>Loading...</p>
  ) : (
    <div className='px-1 sm:px-4 lg:px-32 py-8 sm:py-16'>
      <AptitudeTestCardBar
        minutes={state.overallTimeLeft.minutes}
        seconds={state.overallTimeLeft.seconds}
      />
      <Questions />
    </div>
  );
};

export default Index;
